/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MuscleGroup interface.
 */
export function instanceOfMuscleGroup(value) {
    if (!("id" in value) || value.id === undefined)
        return false;
    if (!("name" in value) || value.name === undefined)
        return false;
    return true;
}
export function MuscleGroupFromJSON(json) {
    return MuscleGroupFromJSONTyped(json, false);
}
export function MuscleGroupFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        id: json.id,
        status: json.status == null ? undefined : json.status,
        name: json.name,
        majorgroup: json.majorgroup == null ? undefined : json.majorgroup,
        enabled: json.enabled == null ? undefined : json.enabled,
        priority: json.priority == null ? undefined : json.priority,
    };
}
export function MuscleGroupToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        id: value.id,
        status: value.status,
        name: value.name,
        majorgroup: value.majorgroup,
        enabled: value.enabled,
        priority: value.priority,
    };
}
