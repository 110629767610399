/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityJobFromJSON, ActivityJobToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class JobsApi extends runtime.BaseAPI {
    /**
     * Handles GET requests for Activities.Controllers.JobsController.
     */
    async activitiesApiUsersUserIdJobsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling activitiesApiUsersUserIdJobsGet().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/jobs`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActivityJobFromJSON));
    }
    /**
     * Handles GET requests for Activities.Controllers.JobsController.
     */
    async activitiesApiUsersUserIdJobsGet(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdJobsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Handles POST requests for Activities.Controllers.JobsController.
     */
    async activitiesApiUsersUserIdJobsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling activitiesApiUsersUserIdJobsPost().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/activities/api/users/{userId}/jobs`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: ActivityJobToJSON(requestParameters.activityJob),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityJobFromJSON(jsonValue));
    }
    /**
     * Handles POST requests for Activities.Controllers.JobsController.
     */
    async activitiesApiUsersUserIdJobsPost(requestParameters, initOverrides) {
        const response = await this.activitiesApiUsersUserIdJobsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
