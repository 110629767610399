/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the EventSource interface.
 */
export function instanceOfEventSource(value) {
    if (!("name" in value) || value.name === undefined)
        return false;
    if (!("id" in value) || value.id === undefined)
        return false;
    return true;
}
export function EventSourceFromJSON(json) {
    return EventSourceFromJSONTyped(json, false);
}
export function EventSourceFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        name: json.name,
        id: json.id,
    };
}
export function EventSourceToJSON(value) {
    if (value == null) {
        return value;
    }
    return {};
}
