/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import { ActivityExecutionFromJSON, ActivityExecutionToJSON, } from "./ActivityExecution";
import { ActivityMaxFromJSON, ActivityMaxToJSON, } from "./ActivityMax";
import { BodyDataFromJSON, BodyDataToJSON, } from "./BodyData";
import { MuscleStatusFromJSON, MuscleStatusToJSON, } from "./MuscleStatus";
import { UserDataFromJSON, UserDataToJSON, } from "./UserData";
/**
 * Check if a given object implements the RecommendationRequest interface.
 */
export function instanceOfRecommendationRequest(value) {
    if (!("userData" in value) || value.userData === undefined)
        return false;
    if (!("bodyData" in value) || value.bodyData === undefined)
        return false;
    if (!("muscleStatus" in value) || value.muscleStatus === undefined)
        return false;
    if (!("activityMaxes" in value) || value.activityMaxes === undefined)
        return false;
    return true;
}
export function RecommendationRequestFromJSON(json) {
    return RecommendationRequestFromJSONTyped(json, false);
}
export function RecommendationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        userData: UserDataFromJSON(json.userData),
        activities: json.activities == null
            ? undefined
            : json.activities.map(ActivityExecutionFromJSON),
        bodyData: BodyDataFromJSON(json.bodyData),
        muscleStatus: mapValues(json.muscleStatus, MuscleStatusFromJSON),
        activityMaxes: mapValues(json.activityMaxes, ActivityMaxFromJSON),
    };
}
export function RecommendationRequestToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        userData: UserDataToJSON(value.userData),
        activities: value.activities == null
            ? undefined
            : value.activities.map(ActivityExecutionToJSON),
        bodyData: BodyDataToJSON(value.bodyData),
        muscleStatus: mapValues(value.muscleStatus, MuscleStatusToJSON),
        activityMaxes: mapValues(value.activityMaxes, ActivityMaxToJSON),
    };
}
