/**
 * Fitnesspilot
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoachTaskFromJSON, CoachTaskToJSON, } from "../models/index";
import * as runtime from "../runtime";
/**
 *
 */
export class TodayApi extends runtime.BaseAPI {
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayGetRaw(requestParameters, initOverrides) {
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling coachTasksApiUsersUserIdCoachTasksTodayGet().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/coachTasks/api/users/{userId}/coachTasks/today`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CoachTaskFromJSON));
    }
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayGet(requestParameters, initOverrides) {
        const response = await this.coachTasksApiUsersUserIdCoachTasksTodayGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayIdConfirmPutRaw(requestParameters, initOverrides) {
        if (requestParameters.id == null) {
            throw new runtime.RequiredError("id", 'Required parameter "id" was null or undefined when calling coachTasksApiUsersUserIdCoachTasksTodayIdConfirmPut().');
        }
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling coachTasksApiUsersUserIdCoachTasksTodayIdConfirmPut().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/coachTasks/api/users/{userId}/coachTasks/today/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayIdConfirmPut(requestParameters, initOverrides) {
        await this.coachTasksApiUsersUserIdCoachTasksTodayIdConfirmPutRaw(requestParameters, initOverrides);
    }
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayIdDismissPutRaw(requestParameters, initOverrides) {
        if (requestParameters.id == null) {
            throw new runtime.RequiredError("id", 'Required parameter "id" was null or undefined when calling coachTasksApiUsersUserIdCoachTasksTodayIdDismissPut().');
        }
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling coachTasksApiUsersUserIdCoachTasksTodayIdDismissPut().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/coachTasks/api/users/{userId}/coachTasks/today/{id}/dismiss`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayIdDismissPut(requestParameters, initOverrides) {
        await this.coachTasksApiUsersUserIdCoachTasksTodayIdDismissPutRaw(requestParameters, initOverrides);
    }
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayIdPatchRaw(requestParameters, initOverrides) {
        if (requestParameters.id == null) {
            throw new runtime.RequiredError("id", 'Required parameter "id" was null or undefined when calling coachTasksApiUsersUserIdCoachTasksTodayIdPatch().');
        }
        if (requestParameters.userId == null) {
            throw new runtime.RequiredError("userId", 'Required parameter "userId" was null or undefined when calling coachTasksApiUsersUserIdCoachTasksTodayIdPatch().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters["Content-Type"] = "application/json";
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);
            if (tokenString) {
                headerParameters.Authorization = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/coachTasks/api/users/{userId}/coachTasks/today/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: CoachTaskToJSON(requestParameters.coachTask),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CoachTaskFromJSON(jsonValue));
    }
    /**
     */
    async coachTasksApiUsersUserIdCoachTasksTodayIdPatch(requestParameters, initOverrides) {
        const response = await this.coachTasksApiUsersUserIdCoachTasksTodayIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
